
  import { defineComponent, reactive, toRefs, onBeforeMount, computed, ref } from 'vue';
  import { useToast } from "vue-toastification";
  import { useRoute } from 'vue-router';
  import { isEqual } from 'lodash';

  import NotificationForm from '@/views/admin/notifications/components/NofiticationForm.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { INotificationForm } from '@/types';
  import { notificationsService } from '@/services';
  import { vuex } from '@/store';
  import { router, routesNames } from '@/router';

  export default defineComponent({
    name: 'UpdateNotification',

    components: { NotificationForm, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const state = reactive({
        notificationForm: {} as INotificationForm,
        originalNotificationData: {} as INotificationForm
      });
      const disabledHandleChanges = ref<boolean>(false);

      const { notificationForm, originalNotificationData } = toRefs(state);

      const disabledAction = computed(() => {
        return isEqual(notificationForm.value, originalNotificationData.value)
          || !notificationForm.value.description.length;
      });

      async function update({ description, externalLink }: INotificationForm) {
        vuex.setLoading(true);

        await notificationsService.updateNotification(
          route.query.id as string,
          { description, externalLink: externalLink || null }
        )
          .then(() => {
            disabledHandleChanges.value = true;
            toast.success('Notification has been updated');
            router.push({ name: routesNames.adminNotificationsList });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchNotification() {
        vuex.setLoading(true);

        return notificationsService.fetchNotification(route.query.id as string)
          .then((res: any) => {
            notificationForm.value = { ...res };
            originalNotificationData.value = { ...res };
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        if (!route.query.id) router.push({ name: routesNames.adminNotifications });
        else { await fetchNotification(); }
      });

      return {
        notificationForm,
        disabledAction,
        disabledHandleChanges,

        update
      };
    }

  });
